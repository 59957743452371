import React from 'react';
import { environment } from '../../../config';

const divStyle = {
  bottom: '4px',
  position: 'fixed' as 'fixed',
  right: '0',
};

const labelStyle = {
  backgroundColor: '#4b6584',
  borderColor: '#46b8da',
  borderRadius: '5px 0 0',
  color: 'white',
  fontSize: '13px',
  padding: '5px 15px 15px 15px',
};

const EnvironmentTag: React.FunctionComponent = () => (
  <div style={divStyle}>
    <label style={labelStyle}>{environment}</label>
  </div>
);

export default EnvironmentTag;
