import React from 'react';
import rollbar from '../../../lib/logging/rollbar';

type Props = {};

type State = {
  error?: Error;
  errorInfo?: any;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: {}) {
    rollbar.error(error.message, error, { errorInfo });

    // Catch errors in any child components and re-renders with an error message
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;
    if (error) {
      // Fallback UI if an error occurs
      return (
        <div>
          <h4>Oh-no! Something went wrong</h4>
          <p className="red">{error && error.toString()}</p>
          <div>Component Stack Error Details: </div>
          <p className="red">{errorInfo.componentStack}</p>
        </div>
      );
    }
    // component normally just renders children
    return children;
  }
}
