import { Constants } from './constants';
import { AuthActions, AuthState } from './types';

const initialState: AuthState = {
  error: null,
  loading: false,
  submitted: null,
  user: null,
};

const authReducer = (state: AuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case Constants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        submitted: null,
      };
    case Constants.FORGOT_PASSWORD_REQUEST:
      return { ...state, error: null, loading: true, submitted: null };
    case Constants.FORGOT_PASSWORD_SUCCESS:
      return { ...state, error: null, loading: false, submitted: true };
    case Constants.SIGNIN_FAILURE:
    case Constants.SIGNUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        submitted: null,
        user: null,
      };
    case Constants.SIGNIN_REQUEST:
    case Constants.SIGNUP_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        submitted: null,
        user: null,
      };
    case Constants.SIGNIN_SUCCESS:
    case Constants.SIGNUP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        submitted: true,
        user: action.payload,
      };
    default: {
      return state;
    }
  }
};

export { authReducer };
