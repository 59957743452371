type Locales = {
  [key: string]: string;
};

const locales: Locales = {
  'en-US': 'English',
  'fr-FR': 'Français',
  'fr-CA': 'Français (CA)',
  'de-DE': 'Deutsch',
  'es-ES': 'Español (ES)',
  'es-LA': 'Español (LA)',
  'pl-PL': 'Polski',
  'pt-BR': 'Português (BR)',
  'zh-CN': '中文 (简体)',
  'zh-TW': '中文 (繁體)',
  'ko-KR': '한국어',
  'ja-JP': '日本語',
  'ru-RU': 'Русский',
  'nl-NL': 'Nederlands',
  'cs-CZ': 'čeština',
  'ms-MY': 'Malay',
  'sv-SE': 'Svenska',
  'th-TH': 'ไทย',
  'vi-VN': 'Tiếng Việt',
};

export default locales;
