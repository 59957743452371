import { auth0 as auth0Config } from '../../config';
import request from '../../lib/api/request';
import { appendUtmKeys } from '../../lib/helpers/tracking';

/* eslint-disable @typescript-eslint/camelcase */
export function createUser(userObject: any) {
  const user = {
    user: appendUtmKeys({
      country_of_residence: userObject.countryOfResidence,
      email: userObject.email,
      market_uuid: userObject.marketUuid,
      name: userObject.name,
      password: userObject.password,
      phone: userObject.phone,
      scope: auth0Config.scope,
      type: userObject.type,
    }),
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return request({
    data: user,
    method: 'POST',
    url: '/user',
  }).then(data => data.user);
}

export function createUserMetadata(metadata: any) {
  return request({
    data: {
      metadata,
    },
    method: 'PUT',
    url: '/user/metadata',
  });
}

export function getUser() {
  return request({ method: 'GET', url: '/user' }).then(data => data.user);
}

export function emailVerifyRequest() {
  return request({ method: 'POST', url: '/user/verify_auth0_email' });
}

export function checkIfImportedUser(userEmail: any) {
  return request({
    method: 'GET',
    params: { email: userEmail },
    url: '/user/imported',
  });
}

export function updateLocale(newLocale: any) {
  return request({
    data: {
      new_locale: newLocale, // eslint-disable-line @typescript-eslint/camelcase
    },
    method: 'PUT',
    url: '/user/locale',
  });
}

export function signToken(token: string) {
  return request({
    headers: { Authorization: `Bearer ${token}` },
    method: 'GET',
    url: '/user/sign_auth0_token',
  });
}
