import { reducer as experimentsReducer } from '@wework/we-experiment-react';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { reducer as formReducer } from 'redux-form';
import { analyticsReducer } from './analytics/reducer';
import { authReducer } from './auth/reducer';
import { countriesReducer } from './countries/reducer';
import { i18nReducer } from './i18n/reducer';
import { sampleReducer } from './sample/reducer';
import { userReducer } from './user/reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    // Core
    router: connectRouter(history),
    analytics: analyticsReducer,
    experiments: experimentsReducer,

    // Modules
    auth: authReducer,
    countries: countriesReducer,
    i18n: i18nReducer,
    sample: sampleReducer,
    user: userReducer,

    // 3rd parties
    form: formReducer,
  });

export default createRootReducer;
