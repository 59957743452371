import jwtDecode from 'jwt-decode';

import { auth0 as auth0Config } from '../../config';
import auth0 from '../../lib/auth/client';
import { userService as API } from '../api';
import User, { checkIfImported } from '../user';
import { UserCookies, UserLocalStorage } from '../user/storage';

export const changePassword = (email: string) =>
  new Promise((resolve, reject) => {
    auth0.changePassword(
      {
        connection: auth0Config.connection,
        email,
      },
      (error, response) => (error ? reject(error) : resolve(response)),
    );
  });
export const resetWithAuth0 = changePassword;

const signout = () => {
  UserCookies.clear();
  UserLocalStorage.clear();
  window.location.href = '/';
};

export const signup = (model: object) =>
  API.createUser(model).then(user => {
    UserLocalStorage.update(new User({ emailVerified: false, ...user }));
    return user;
  });

export const signinWithAuth0 = ({ email, password }: { email: string; password: string }) =>
  new Promise((resolve, reject) => {
    auth0.client.login(
      {
        password,
        realm: auth0Config.connection,
        scope: auth0Config.scope,
        username: email,
      },
      (error, result) => {
        if (error) {
          checkIfImported(email)
            .then(() => reject(error))
            .catch(importedError => reject(importedError));
        } else {
          UserCookies.clear();
          API.signToken(result.idToken)
            .then(({ token }) => {
              UserCookies.set(token);
              resolve();
            })
            .catch(importedError => reject(importedError));
        }
      },
    );
  });

const isExpired = (decodedToken: any) => {
  const currentTime = () => new Date().getTime() / 1000;
  return decodedToken.exp <= currentTime();
};

export const isAuthenticated = () => {
  const token = UserCookies.get();

  const decodedToken = token && jwtDecode(token);

  if (!decodedToken) return false;

  const expired = isExpired(decodedToken);

  if (expired) signout();

  return true;
};
