import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';

/*
Note: logger must be the last middleware in chain, otherwise it will log thunk and promise, not actual actions.
See @ https://github.com/LogRocket/redux-logger
*/

const reduxLogger = createLogger({
  collapsed: true,
  level: 'info',
  predicate: (getState, action) => {
    const shouldSkipLog = action && action.meta && action.meta.skipLog;
    return process.env.NODE_ENV === 'development' && !shouldSkipLog;
  },
}) as Middleware;

export default reduxLogger;
