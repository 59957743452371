import { locale as localeSettings } from '../../config';
import caES from '../../translations/ca-ES.json';
import ca from '../../translations/ca.json';
import csCZ from '../../translations/cs-CZ.json';
import daDK from '../../translations/da-DK.json';
import deDE from '../../translations/de-DE.json';
import enUS from '../../translations/en-US.json';
import en from '../../translations/en.json';
import esES from '../../translations/es-ES.json';
import esLA from '../../translations/es-LA.json';
import frCA from '../../translations/fr-CA.json';
import frFR from '../../translations/fr-FR.json';
import fr from '../../translations/fr.json';
import idID from '../../translations/id-ID.json';
import itIT from '../../translations/it-IT.json';
import jaJP from '../../translations/ja-JP.json';
import koKR from '../../translations/ko-KR.json';
import msMY from '../../translations/ms-MY.json';
import nbNO from '../../translations/nb-NO.json';
import nlNL from '../../translations/nl-NL.json';
import plPL from '../../translations/pl-PL.json';
import ptBR from '../../translations/pt-BR.json';
import ptPT from '../../translations/pt-PT.json';
import ruRU from '../../translations/ru-RU.json';
import svSE from '../../translations/sv-SE.json';
import thTH from '../../translations/th-TH.json';
import viVN from '../../translations/vi-VN.json';
import zhCN from '../../translations/zh-CN.json';
import zhTW from '../../translations/zh-TW.json';

export type LocaleMessages = {
  [key: string]: string;
};

type MessagesByLocale = {
  [key: string]: LocaleMessages;
};

const messagesByLocale: MessagesByLocale = {
  ca,
  'ca-ES': caES,
  'cs-CZ': csCZ,
  'da-DK': daDK,
  'de-DE': deDE,
  en,
  'en-US': enUS,
  'es-ES': esES,
  'es-LA': esLA,
  fr,
  'fr-CA': frCA,
  'fr-FR': frFR,
  'id-ID': idID,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'ms-MY': msMY,
  'nb-NO': nbNO,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'sv-SE': svSE,
  'th-TH': thTH,
  'vi-VN': viVN,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

const getMessagesByLocale = (locale: string) => {
  const localeMessages = messagesByLocale[locale] || messagesByLocale[localeSettings.default];
  return localeMessages;
};

export const getLocaleMessages = (locale: string) => getMessagesByLocale(locale);

export default { getLocaleMessages };
