import { Constants } from './constants';
import { AnalyticsActions, AnalyticsState } from './types';

const initialState: AnalyticsState = {};

const analyticsReducer = (state = initialState, action: AnalyticsActions) => {
  switch (action.type) {
    case Constants.TRACK: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
};

export { analyticsReducer };
