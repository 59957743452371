import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { environment } from '../../config';
import { Environment } from '../../config/environment';
import { RoutePath } from '../../config/routes';
import AppContainer from '../../containers/App';
import LazyForgotPassword from '../../pages/ForgotPassword/lazy';
import NoMatch from '../../pages/NoMatch';
import LazySamples from '../../pages/Samples/lazy';
import LazySignin from '../../pages/Signin/lazy';
import LazySignup from '../../pages/Signup/lazy';
import store, { history } from '../../redux/store';
import EnvironmentTag from '../core/EnvironmentTag';
import ErrorBoundary from '../core/ErrorBoundary';
import HeadManager from '../core/HeadManager';
import I18nProvider from '../core/I18nProvider';
import PageLoader from '../core/PageLoader';
import SmartlingContextCaptureTag from '../core/SmartlingContextCaptureTag';
import ReferralsTheme from '../themes/default';
import './app.scss';

const App: React.FunctionComponent = () => (
  <ReferralsTheme>
    <Provider store={store}>
      <I18nProvider>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <AppContainer>
              <HeadManager
                defaultTitle="React Web App"
                metaDescriptionContent="Referral Program web app default description"
                titleTemplate="%s - Referral Program"
              />
              <React.Suspense fallback={<PageLoader />}>
                <Switch>
                  <Route component={LazySamples} exact path={RoutePath.ROOT} />
                  <Route component={LazySignup} path={RoutePath.SIGNUP} />
                  <Route component={LazySignin} path={RoutePath.SIGNIN} />
                  <Route component={LazyForgotPassword} path={RoutePath.FORGOT_PASSWORD} />
                  <Route component={NoMatch} />
                </Switch>
              </React.Suspense>
              {environment !== Environment.PRODUCTION && <EnvironmentTag />}
              {environment === Environment.STAGING && <SmartlingContextCaptureTag />}
            </AppContainer>
          </ErrorBoundary>
        </ConnectedRouter>
      </I18nProvider>
    </Provider>
  </ReferralsTheme>
);

export default App;
