import { Constants } from './constants';
import { CountriesActions, CountriesState } from './types';

const initialState: CountriesState = {
  allCountriesInTheWorld: [],
  countries: [],
  error: null,
  loading: false,
};

const countriesReducer = (state: CountriesState = initialState, action: CountriesActions) => {
  switch (action.type) {
    case Constants.COUNTRIES_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case Constants.COUNTRIES_FETCH_REQUEST:
      return { ...state, error: null, loading: true };
    case Constants.COUNTRIES_FETCH_SUCCESS:
      return {
        ...state,
        allCountriesInTheWorld: action.payload.allCountriesInTheWorld,
        countries: action.payload.countries,
        error: null,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export { countriesReducer };
