import { addLocaleData } from 'react-intl';

import caLocaleData from 'react-intl/locale-data/ca';
import csLocaleData from 'react-intl/locale-data/cs';
import deLocaleData from 'react-intl/locale-data/de';
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import frLocaleData from 'react-intl/locale-data/fr';
import jaLocaleData from 'react-intl/locale-data/ja';
import koLocaleData from 'react-intl/locale-data/ko';
import msLocaleData from 'react-intl/locale-data/ms';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import ruLocaleData from 'react-intl/locale-data/ru';
import svLocaleData from 'react-intl/locale-data/sv';
import thLocaleData from 'react-intl/locale-data/th';
import viLocaleData from 'react-intl/locale-data/vi';
import zhLocaleData from 'react-intl/locale-data/zh';

export const localeData = [
  caLocaleData,
  enLocaleData,
  thLocaleData,
  msLocaleData,
  deLocaleData,
  ruLocaleData,
  svLocaleData,
  frLocaleData,
  koLocaleData,
  nlLocaleData,
  esLocaleData,
  viLocaleData,
  csLocaleData,
  jaLocaleData,
  zhLocaleData,
  plLocaleData,
  ptLocaleData,
];

// load the locale data for languages we want to support
export const addLocalesData = () => localeData.forEach(locale => addLocaleData(locale));

export default addLocalesData;
