import Rollbar from 'rollbar';
import { app, environment, rollbar } from '../../config';
import { Environment } from '../../config/environment';

/* See Configuration and Method Reference @ Rollbar:
https://docs.rollbar.com/docs/rollbarjs-configuration-reference */

const options = {
  accessToken: rollbar.token,
  // This determines whether or not the client reports all uncaught exceptions to Rollbar by default. Default: false
  captureUncaught: true,
  // This determines whether or not the client reports all uncaught rejections to Rollbar by default. Default: false
  captureUnhandledRejections: true,
  // If set to false, no data will be sent to Rollbar for this notifier. Default: true
  enabled: environment !== Environment.DEVELOPMENT,
  // When false, transmit all error events including consecutive duplicate errors. When true, only send the first error when identical consecutive errors are detected. Default: true
  ignoreDuplicateErrors: true,
  payload: {
    client: {
      /* eslint-disable @typescript-eslint/camelcase */
      javascript: {
        code_version: app.version,
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
      /* eslint-enable @typescript-eslint/camelcase */
    },
    environment,
    version: `${app.name}.${app.version}`,
  },
  // Determines whether to send Rollbar events to the Rollbar API. When unset, events will be fully processed, but not sent. Default: true
  transmit: true,
  // This determines whether or not the client also logs to console.log, in addition to sending the item to Rollbar. Default: false
  verbose: false,
  // In the browser environment, Rollbar wraps many global event handlers in order to provide more detailed information when an error occurs in one of these handlers. Set this flag false to disable these wrappers. Default: true
  wrapGlobalEventHandlers: true,
};

const client = Rollbar.init(options);

export default client;
