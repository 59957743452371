import { action, createAsyncAction } from 'typesafe-actions';
import { getCountries } from '../../../services/api/countries';
import { Constants } from './constants';
import { FetchCountriesApiResponse } from './types';

interface FetchCountriesSuccessPayload {}

// ACTION CREATORS
export function fetchCountriesRequest() {
  return action(Constants.COUNTRIES_FETCH_REQUEST, {});
}

export function fetchCountriesSuccess(countries: any) {
  return action(Constants.COUNTRIES_FETCH_SUCCESS, {
    allCountriesInTheWorld: countries.allCountriesInTheWorld,
    countries: countries.countries,
  });
}
export function fetchCountriesFailure(error: any) {
  return action(Constants.COUNTRIES_FETCH_FAILURE, { error });
}

const fetchCountriesAsync = createAsyncAction(
  Constants.COUNTRIES_FETCH_REQUEST,
  Constants.COUNTRIES_FETCH_SUCCESS,
  Constants.COUNTRIES_FETCH_FAILURE,
)<any, FetchCountriesSuccessPayload, Error>();

export const fetchCountriesApi = () => (dispatch: any) => {
  dispatch(fetchCountriesAsync.request({}));
  return getCountries()
    .then((countries: FetchCountriesApiResponse) => dispatch(fetchCountriesAsync.success(countries)))
    .catch((error: Error) => {
      dispatch(fetchCountriesAsync.failure(error));
      throw error;
    });
};
