export enum Constants {
  FORGOT_PASSWORD_REQUEST = '@@auth/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = '@@auth/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = '@@auth/FORGOT_PASSWORD_FAILURE',
  SIGNUP_REQUEST = '@@auth/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = '@@auth/SIGNUP_SUCCESS',
  SIGNUP_FAILURE = '@@auth/SIGNUP_FAILURE',
  SIGNIN_REQUEST = '@@auth/SIGNIN_REQUEST',
  SIGNIN_SUCCESS = '@@auth/SIGNIN_SUCCESS',
  SIGNIN_FAILURE = '@@auth/SIGNIN_FAILURE',
}
