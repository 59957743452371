import { camelizeKeys } from 'humps';
import { content } from '../../config';
import { detectLocale } from '../../lib/i18n/localeDetect';
import { userService as API } from '../api';
import { UserCookies, UserLocalStorage } from './storage';

export enum UserType {
  affiliate = 'affiliate',
  broker = 'broker',
  employee = 'employee',
  member = 'member',
}

class User {
  private id!: number;

  private uuid!: string;

  private email!: string;

  private name!: string;

  private type!: UserType;

  private phone!: string;

  private locale!: string;

  private createdAt!: string;

  private emailVerified?: boolean;

  constructor(attributes = {}) {
    return { ...this, ...camelizeKeys(attributes) };
  }

  isExternalUser() {
    return this.isBroker() || this.isAffiliate();
  }

  isBroker() {
    return this.type === UserType.broker;
  }

  isEmployee() {
    return this.type === UserType.employee;
  }

  isMember() {
    return this.type === UserType.member;
  }

  isAffiliate() {
    return this.type === UserType.affiliate;
  }

  displayName() {
    return this.name ? this.name.split(' ', 1) : '';
  }

  tosLink() {
    return `/${detectLocale()}/tos/${this.type}`;
  }

  supportEmail() {
    return content.support[this.isBroker() ? 'brokers' : 'nonbrokers'].email;
  }

  getHelpLink() {
    return `mailto:${this.supportEmail()}`;
  }

  updateAttributes(updates: object) {
    UserLocalStorage.update(new User({ ...this, ...updates }));
  }
}

export const checkIfImported = (email: string) =>
  API.checkIfImportedUser(email).then(response => {
    if (response.imported) {
      // TODO: translate('auth.messages.preexisting_user')
      throw Error('preexisting user');
    }
  });

export const currentUser = () => {
  const userFromCookie = UserCookies.get();
  const userFromLocalStorage = UserLocalStorage.get();
  if (!userFromCookie || !userFromLocalStorage) return false;
  return new User(userFromLocalStorage);
};

export default User;
