import { Constants } from './constants';
import { UserActions, UserState } from './types';

const initialState: UserState = {
  error: null,
  loading: false,
  submitted: null,
  user: null,
};

const userReducer = (state: UserState = initialState, action: UserActions) => {
  switch (action.type) {
    case Constants.LOAD_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        submitted: null,
      };
    case Constants.LOAD_USER_REQUEST:
      return { ...state, error: null, loading: true, submitted: null };
    case Constants.LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        error: null,
        loading: false,
        submitted: true,
      };
    default: {
      return state;
    }
  }
};

export { userReducer };
