import React from 'react';
import Helmet from 'react-helmet';

type Props = {
  defaultTitle?: string;
  enableMetaRobotsIndexAndFollow?: boolean;
  enableMetaViewportResponsiveDesign?: boolean;
  linkCanonicalHref?: string;
  linkFaviconHref?: string;
  metaDescriptionContent?: string;
  title?: string;
  titleTemplate?: string;
};

const titleTag = (title?: string) => title && <title>{title}</title>;
const metaTag = (name: string, content: string) => <meta content={content} name={name} />;

const metaRobotsTag = (enableIndexAndFollow: boolean) =>
  metaTag('robots', enableIndexAndFollow ? 'index, follow' : 'noindex, nofollow');

const metaViewportResponsiveDesignTag = (enableResponsiveDesign: boolean) => {
  return enableResponsiveDesign && metaTag('viewport', 'width=device-width,initial-scale=1, shrink-to-fit=no');
};

const linkCanonicalTag = (href?: string) => {
  return href && <link href={href} rel="canonical" />;
};

const metaDescriptionContentTag = (content?: string) => {
  return content && metaTag('description', content);
};

const linkFaviconTag = (linkFaviconHref?: string) => {
  return linkFaviconHref && <link href={linkFaviconHref} rel="icon" sizes="16x16" type="image/png" />;
};

const HeadManager = ({
  defaultTitle,
  enableMetaRobotsIndexAndFollow = false, // Disable tracking as long as were not live
  enableMetaViewportResponsiveDesign = true,
  linkCanonicalHref,
  linkFaviconHref,
  metaDescriptionContent,
  title,
  titleTemplate,
}: Props) => {
  return (
    <Helmet {...{ defaultTitle }} {...{ titleTemplate }}>
      {titleTag(title)}
      {metaRobotsTag(enableMetaRobotsIndexAndFollow)}
      {metaViewportResponsiveDesignTag(enableMetaViewportResponsiveDesign)}
      {linkCanonicalTag(linkCanonicalHref)}
      {metaDescriptionContentTag(metaDescriptionContent)}
      {linkFaviconTag(linkFaviconHref)}
    </Helmet>
  );
};

export default HeadManager;
