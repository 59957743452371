import { action, createAsyncAction } from 'typesafe-actions';
import { userService as API } from '../../../services/api';
import { isAuthenticated } from '../../../services/auth/index';
import User, { currentUser } from '../../../services/user';
import { UserLocalStorage } from '../../../services/user/storage';
import { Constants } from './constants';

interface LoadUserSuccessPayload {}

// ACTION CREATORS
export function loadUserRequest() {
  return action(Constants.LOAD_USER_REQUEST);
}
export function loadUserSuccess(user: any) {
  return action(Constants.LOAD_USER_SUCCESS, { user });
}
export function loadUserFailure(error: any) {
  return action(Constants.LOAD_USER_FAILURE, { error });
}

const loadUserAsync = createAsyncAction(
  Constants.LOAD_USER_REQUEST,
  Constants.LOAD_USER_SUCCESS,
  Constants.LOAD_USER_FAILURE,
)<void, LoadUserSuccessPayload, Error>();

export const loadUserApi = () => (dispatch: any) => {
  dispatch(loadUserAsync.request());

  API.getUser()
    .then((user: any) => {
      UserLocalStorage.update(new User(user));
      dispatch(loadUserSuccess(user));
    })
    .catch((error: Error) => {
      dispatch(loadUserFailure(error));
      throw error;
    });
};

export const loadUser = () => (dispatch: any) => {
  if (isAuthenticated()) {
    const user = currentUser();
    return user ? dispatch(loadUserSuccess(user)) : dispatch(loadUserApi());
  }
};
