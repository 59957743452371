import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { fetchCountriesApi } from '../../redux/modules/countries/actions';
import { reinitializeLocale } from '../../redux/modules/i18n/actions';
import { loadUser } from '../../redux/modules/user/actions';

type Props = {
  reinitializeLocaleConnect: typeof reinitializeLocale;
  fetchCountriesApiConnect: typeof fetchCountriesApi;
  loadUserConnect: typeof loadUser;
} & RouteComponentProps;

class AppContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { fetchCountriesApiConnect } = this.props;
    fetchCountriesApiConnect();
  }

  componentDidMount() {
    const { loadUserConnect } = this.props;
    loadUserConnect();
  }

  componentDidUpdate(prevProps: Props) {
    const { location, reinitializeLocaleConnect } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      reinitializeLocaleConnect();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  fetchCountriesApiConnect: () => dispatch(fetchCountriesApi()),
  loadUserConnect: () => dispatch(loadUser()),
  reinitializeLocaleConnect: () => dispatch(reinitializeLocale()),
});

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(AppContainer));
