import { privacy, support } from './content';
import locales from './locales';

type AppConfig = {
  branch: string;
  gitVersion: string;
  name: string;
  version: string;
};

type LocaleConfig = {
  allowed: Array<string>;
  default: string;
  localStorageKey: string;
};

type TokenConfig = { token?: string };
type RollbarConfig = TokenConfig;
type LogEntriesConfig = TokenConfig;
type Split = { experiments: { [x: string]: string } };
type SplitConfig = TokenConfig & Split;
type SegmentConfig = TokenConfig;
type Contentful = { spaceId?: string };
type ContentfulConfig = TokenConfig & Contentful;
type SmartlingConfig = { organizationId?: string };

export const app: AppConfig = {
  branch: process.env.REACT_BRANCH || '',
  gitVersion: process.env.REACT_APP_GIT_VERSION || '',
  name: process.env.REACT_APP_NAME || '',
  version: process.env.REACT_APP_VERSION || '',
};

export const smartling: SmartlingConfig = {
  organizationId: process.env.REACT_APP_SMARTLING_ORGANIZATION_ID,
};

export const locale: LocaleConfig = {
  allowed: Object.keys(locales),
  default: 'en',
  localStorageKey: 'APP_LOCALE',
};

export const environment: string = process.env.NODE_ENV;

export const rollbar: RollbarConfig = {
  token: process.env.REACT_APP_ROLLBAR_TOKEN,
};

export const logEntries: LogEntriesConfig = {
  token: process.env.REACT_APP_LOGENTRIES_TOKEN,
};

export const split: SplitConfig = {
  experiments: {
    TESTING_FLAG: 'referrals_testing_flag',
  },
  token: process.env.REACT_APP_SPLIT_TOKEN,
};

export const segment: SegmentConfig = {
  token: process.env.REACT_APP_SEGMENT_TOKEN,
};

export const contentful: ContentfulConfig = {
  spaceId: process.env.REACT_APP_CONTENTFUL_SPACE_ID_TEST,
  token: process.env.REACT_APP_CONTENTFUL_TOKEN_TEST,
};

export const api = {
  baseUrl: process.env.REACT_APP_API_URL,
  timeoutInSeconds: 60,
};

export const webApp = {
  url: process.env.REACT_APP_WEB_APP_URL || '',
};

export const auth0 = {
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  connection: process.env.REACT_APP_AUTH0_CONNECTION_NAME || '',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  scope: 'openid offline_access',
};

export const content = {
  privacy,
  support,
};

const ConfigOptions = {
  api,
  auth0,
  app,
  content,
  contentful,
  environment,
  locale,
  logEntries,
  rollbar,
  segment,
  split,
  webApp,
};
export const Config = ConfigOptions;
window.showConfig = () => Config;
export default ConfigOptions;
