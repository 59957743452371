import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import addLocalesData from '../../../lib/i18n/localeData';
import { LocaleMessages } from '../../../lib/i18n/localeMessages';
import { RootState } from '../../../redux/modules/types';

type Props = {
  locale: string;
  messages?: LocaleMessages;
};

class I18nProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    addLocalesData();
  }

  render() {
    const { children, locale, messages } = this.props;
    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ i18n }: RootState) => ({
  locale: i18n.locale,
  messages: i18n.messages,
});

export default connect(mapStateToProps)(I18nProvider);
