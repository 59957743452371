import axios, { AxiosTransformer } from 'axios';
import humps from 'humps';
import { api } from '../../config';
import { UserCookies } from '../../services/user/storage';
import { detectLocale } from '../i18n/localeDetect';
import consoleLogger from '../logging/console';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: api.baseUrl,
  timeout: 1000 * api.timeoutInSeconds,
  transformRequest: [
    /* TODO: might be changed according to baseurl */
    (data, headers) => {
      const token = UserCookies.get();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const locale = detectLocale();
      if (locale) {
        headers['Accept-Language'] = locale;
      }
      return humps.decamelizeKeys(data);
    },
    ...(axios.defaults.transformRequest as AxiosTransformer[]),
  ],
});

/**
 * Request Wrapper with default fulfilled/rejected handlers
 */
const request = (options: any) => {
  const time = new Date().getTime();

  const formatLog = (message: string) => `(${time}) Request ${message}`;

  const onSuccess = (response: any) => {
    consoleLogger.info(formatLog('Success'), response);
    return response.data;
  };

  const onError = (error: any) => {
    consoleLogger.error(formatLog('Failed'), error.config);

    if (error.response) {
      // Request was made but
      // server responded with something other than 2xx
      consoleLogger.error('Status:', error.response.status);
      consoleLogger.error('Data:', error.response.data);
      consoleLogger.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      consoleLogger.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  consoleLogger.info(formatLog('Started'), options);

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;
