import React from 'react';
import consoleLogger from '../../../lib/logging/console';
import smartlingContextCaptureScript from '../../../lib/smartling/context-capture';

const divStyle = {
  bottom: '4px',
  left: '0',
  position: 'fixed' as 'fixed',
};

const buttonStyle = {
  backgroundColor: '#4b6584',
  borderColor: '#46b8da',
  borderRadius: '0 5px 0 0',
  color: 'white',
  fontSize: '13px',
  padding: '5px 15px 15px 15px',
};

type Props = {};
type State = {
  enabled: boolean;
};

class SmartlingContextCaptureTag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Load Smartling context capture script async
    smartlingContextCaptureScript.init((nextState: boolean) => this.setState({ enabled: nextState }));

    this.state = {
      enabled: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { enabled } = this.state;

    if (typeof window.SmartlingContextTracker !== 'undefined') {
      const newState = !enabled;
      this.setState({
        enabled: newState,
      });

      if (newState) {
        consoleLogger.info('Enabling Capture Context');
        // Re­enable capture after it has been disabled or paused.
        window.SmartlingContextTracker.enable();
      } else {
        consoleLogger.info('Disabling Capture Context');
        // Disable capture indefinitely for current browser.
        window.SmartlingContextTracker.disable();
      }
    }
  }

  render() {
    const { enabled } = this.state;

    return (
      <div style={divStyle}>
        <label onClick={this.handleClick} style={buttonStyle}>
          {enabled ? '▣ Disable' : '▶ Enable'}
        </label>
      </div>
    );
  }
}

export default SmartlingContextCaptureTag;
