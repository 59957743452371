import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createTracker } from 'redux-segment';
import thunk from 'redux-thunk';
import reduxLogger from '../../lib/logging/reduxLogger';
import createRootReducer from '../modules';
import customMapper from './customMapper';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const tracker = createTracker(customMapper);

export const history = createBrowserHistory();

export default () =>
  createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, tracker, reduxLogger)),
  );
