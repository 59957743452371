import { detectLocale, setLocalStorageLocale } from '../../../lib/i18n/localeDetect';
import { getLocaleMessages } from '../../../lib/i18n/localeMessages';
import { Constants } from './constants';
import { LocaleActions, LocaleState } from './types';

// REDUCER

const createInitialState = () => {
  const locale = detectLocale();
  const messages = getLocaleMessages(locale);
  return { locale, messages };
};

const initialState: LocaleState = createInitialState();

const i18nReducer = (state: LocaleState = initialState, action: LocaleActions) => {
  switch (action.type) {
    case Constants.REINITIALIZE_LOCALE: {
      return createInitialState();
    }
    case Constants.UPDATE_LOCALE: {
      setLocalStorageLocale(action.payload.locale);
      return {
        ...state,
        locale: action.payload.locale,
        messages: getLocaleMessages(action.payload.locale),
      };
    }
    default: {
      return state;
    }
  }
};

export { i18nReducer };
