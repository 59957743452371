import { action } from 'typesafe-actions';
import { Constants } from './constants';

// ACTION CREATORS
export function updateLocale(locale: string) {
  return action(Constants.UPDATE_LOCALE, { locale });
}

export function reinitializeLocale() {
  return action(Constants.REINITIALIZE_LOCALE);
}
