import * as log from 'loglevel';
import { environment } from '../../config';
import { Environment } from '../../config/environment';

const LOGGER_NAME = 'Default';
const DEFAULT_LOG_LEVEL = log.levels.INFO;

/* TODO: set according to environment */
if (environment !== Environment.PRODUCTION) {
  log.setLevel(DEFAULT_LOG_LEVEL);
}

const consoleLogger = log.getLogger(LOGGER_NAME);

/* Expose via window to allow on-the-fly debugging (for adjusting log.setLevel) */
window.log = consoleLogger;

export default consoleLogger;
