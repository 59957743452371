import { camelCase, mapKeys } from 'lodash';
import { UserCookies } from '../../services/user/storage';

const toCamel = (key: string) => camelCase(key).replace(/^u/, 'utm');

const extractUTMFromCookie = () => {
  const utms = UserCookies.getUTMs();
  return mapKeys(utms, (value, key) => toCamel(key));
};

export function appendUtmKeys(obj: any) {
  const utm = extractUTMFromCookie();
  return { ...obj, ...utm };
}
