import { Constants } from './constants';
import { Sample, SampleActions, SampleState } from './types';

// REDUCER

const initialState: SampleState = {
  errors: undefined,
  items: [{ id: 1, title: 'sample-1' }, { id: 2, title: 'sample-2' }, { id: 3, title: 'sample-3' }],
  loading: false,
  selectedItem: undefined,
};

const getNextId = (items: Sample[]) => {
  return items.reduce((maxId, sample) => Math.max(maxId, sample.id), 0) + 1;
};

const sampleReducer = (state: SampleState = initialState, action: SampleActions) => {
  switch (action.type) {
    case Constants.ADD_SAMPLE_ITEM: {
      const nextId = getNextId(state.items);
      return {
        ...state,
        items: state.items.concat({
          id: nextId,
          title: `sample-${nextId}`,
        }),
      };
    }
    case Constants.DELETE_SAMPLE_ITEM: {
      return {
        ...state,
        items: state.items.filter(sampleItem => sampleItem.id !== action.payload.id),
        selectedItem: undefined,
      };
    }
    case Constants.SELECT_SAMPLE_ITEM: {
      return {
        ...state,
        selectedItem: state.items.find(sample => sample.id === action.payload.id),
      };
    }
    default: {
      return state;
    }
  }
};

export { sampleReducer };
