import { UserType } from '../services/user/index';

enum RouteParam {
  LOCALE = ':locale?',
  USER_TYPE = ':type',
}

const routePath = (routeState: RouteState) => `/${RouteParam.LOCALE}/${RouteParam.USER_TYPE}/${routeState}`;

export enum RouteState {
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'forgot-password',
}

export const RoutePath = {
  FORGOT_PASSWORD: routePath(RouteState.FORGOT_PASSWORD),
  ROOT: '/',
  SIGNIN: routePath(RouteState.SIGNIN),
  SIGNUP: routePath(RouteState.SIGNUP),
};

/**
 * Clears _parameters_ (prefixed with " : ") from route.
 * Useful when you want to generate a path without providing all params.
 * For example: "/:locale/some/thing" -> "/some/thing"
 * @param routePath
 */
const clearRouteParameters = (path: string) => {
  const routePathParts = path.split('/');
  const clearRoutePathParts: Array<string> = [];

  routePathParts.forEach(value => {
    if (value && !value.startsWith(':')) {
      clearRoutePathParts.push(value);
    }
  });

  return clearRoutePathParts.join('/');
};

/**
 * Generate a given route with a given user type.
 * It will also clear other parameters.
 * @param userType
 * @param routeState
 */
export const userRoute = (userType: UserType, routeState: RouteState) =>
  clearRouteParameters(routePath(routeState).replace(RouteParam.USER_TYPE, `${userType}s`));
