import { smartling } from '../../config';

// JavaScript Context Capture Library
// Include script that creates SmartlingContextTracker object in global scope
// Reference: https://help.smartling.com/hc/en-us/articles/360007996553-JavaScript-Context-Capture-Library

const initScript = (callback: any) => {
  (function(w: any, o) {
    try {
      const headElement = document.getElementsByTagName('head')[0];
      const scriptTag: any = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.async = 1;
      scriptTag.crossorigin = 'anonymous';
      scriptTag.src = '//d2c7xlmseob604.cloudfront.net/tracker.min.js';
      scriptTag.onload = function() {
        const loadState = w.SmartlingContextTracker.init({ orgId: o });
        w.SmartlingContextTracker.loadState = loadState;
        callback(loadState);
      };
      headElement.insertBefore(scriptTag, headElement.firstChild);
    } catch (ex) {
      // Ignore.
    }
  })(window, smartling.organizationId);
};

export default {
  init: initScript,
};
