import { Theme } from '@wework/rivendell-components/Theme';
import React from 'react';

const themeOverride = (theme: any) => ({
  frlz: {
    footer: {
      height: {
        large: 249,
        medium: 368,
        small: 368,
      },
      select: {
        width: {
          medium: 300,
        },
      },
    },
    header: {
      height: '80px',
    },
    'rd-background-color': '#FCFCF9',
  },
  styles: theme.styles,
});

const ReferralsTheme = (props: any) => <Theme reset={false} themeOverride={themeOverride} {...props} />;

export default ReferralsTheme;
