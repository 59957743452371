import { locale as localeSettings } from '../../config';

const getLocalStorageLocale = () => {
  const { localStorage } = window;
  return localStorage.getItem(localeSettings.localStorageKey);
};

export const getRoutePathLocale = () =>
  localeSettings.allowed.find(locale => {
    const { location } = window;
    const localeInPath = location.pathname.split('/');
    const routePathLocale = localeInPath.length > 1 && localeInPath[1] === locale;
    return routePathLocale;
  });

const getNavigatorLocale = () => {
  const { navigator } = window;
  const navigatorLocale =
    // Chrome / Firefox
    (navigator.languages && navigator.languages[0]) ||
    // All browsers
    navigator.language ||
    // IE <= 10
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage;

  return navigatorLocale;
};

const getPreferredLocale = () => {
  const localStorageLocale = getLocalStorageLocale();
  const preferedLocale = localStorageLocale || getNavigatorLocale();
  const localeFullMatch = localeSettings.allowed.find(locale => preferedLocale === locale);
  return localeFullMatch;
};

const getPartialMatchLocale = () => {
  const localePartialMatch = localeSettings.allowed.find(locale => locale.split('-')[0] === locale);
  return localePartialMatch;
};

export const setLocalStorageLocale = (locale: string) => {
  const { localStorage } = window;
  localStorage.setItem(localeSettings.localStorageKey, locale);
};

export const detectLocale = () => {
  return getRoutePathLocale() || getPreferredLocale() || getPartialMatchLocale() || localeSettings.default;
};

export default { detectLocale, setLocalStorageLocale };
