import { EventTypes } from 'redux-segment';
import { Constants as AuthConstants } from '../modules/auth/constants';

const handleFormEvent = (getState: Function, action: any) => {
  const { router, user } = getState();

  const userObj = user.user && {
    /* eslint-disable @typescript-eslint/camelcase */
    user_id: user.user.id,
    user_market_uuid: user.user.marketUUid || '',
    user_type: user.user.type,
    /* eslint-enable @typescript-eslint/camelcase */
  };

  return {
    eventPayload: {
      event: 'form-submit',
      properties: {
        category: 'Form Submission',
        /* eslint-disable @typescript-eslint/camelcase */
        created_at: new Date(Date.now()).toUTCString(),
        current_host: window.location.host,
        current_page: router.location.pathname,
        form_errors: action.error,
        form_type: action.meta.form,
        /* eslint-enable @typescript-eslint/camelcase */
        label: `referrals_${action.meta.form}`,
        target: '',
        type: 'link',
        ...userObj,
      },
    },
    eventType: EventTypes.track,
  };
};

const buildIdentifyEvent = (user: any) => ({
  eventPayload: {
    properties: {
      user,
    },
  },
  eventType: EventTypes.identify,
});

const handleLocationChange = (getState: Function, action: any) => {
  const { user } = getState();

  return [
    ...(action.payload.isFirstRendering && user ? [buildIdentifyEvent(user)] : []),
    {
      eventPayload: {
        event: action.payload.location.pathname,
        properties: {
          name: action.payload.location.pathname,
        },
      },
      eventType: EventTypes.page,
    },
  ];
};

const handleIdentify = (getState: Function) => {
  const { user } = getState();
  return buildIdentifyEvent(user);
};

export default {
  mapper: {
    '@@redux-form/SET_SUBMIT_FAILED': handleFormEvent,
    '@@redux-form/SET_SUBMIT_SUCCEEDED': handleFormEvent,
    '@@router/LOCATION_CHANGE': handleLocationChange,
    [AuthConstants.SIGNIN_SUCCESS]: handleIdentify,
  },
  skipDefaultMapping: true,
};
