type Support = {
  brokers: SupportDetails;
  nonbrokers: SupportDetails;
};

type SupportDetails = {
  email: string;
  phone: string;
};

export const support: Support = {
  brokers: {
    email: 'brokers@wework.com',
    phone: '844-557-0832',
  },
  nonbrokers: {
    email: 'referrals@wework.com',
    phone: '877-801-4339',
  },
};

export const privacy = {
  weworkPrivacyLink: 'https://www.wework.com/legal/privacy/',
};
